<template>
  <section class="banner">
    <div class="banner__cols">
      <div class="banner__content">
        <div class="banner__text">
          <p>Халяль ресторан татарской кухни</p>
        </div>
        <h1 class="banner__title">
          Встреча вкуса и искусства на одной тарелке
        </h1>
        <button
          class="banner__button"
          @click="$router.push('/reservation')"
          onclick="ym(95816218,'reachGoal','brone'); return true;"
        >
          Забронировать
        </button>
      </div>
      <div class="banner__image"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "main-page-banner",
};
</script>

<style lang="scss" scoped>
.banner {
  background-color: #f8f4eb;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 75vh;
    display: flex;
  }
  &__cols {
    display: flex;
    width: 100%;
  }
  &__image {
    background-color: #f8f4eb;
    background-image: url("../assets/images/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: 100vh;
    position: relative;
    background-position: center;
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
  // .banner__content
  &__content {
    color: #ffffff;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    padding: 3rem;
    @media (max-width: 767px) {
      width: 100%;
      padding: 1rem;
      justify-content: flex-end;
      box-shadow: inset 0px -200px 20px 0px rgba(0, 0, 0, 0.5);
    }
  }

  // .banner__text
  &__text {
    font-family: "Nunito";
    font-weight: 400;
    line-height: 34px;
    color: #373737;
    font-size: clamp(12px, 5vw, 30px);
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      line-height: 16.37px;
      color: #fff;
      padding: 10px 0;
      p {
        text-shadow: 2px 2px 2px black;
      }
    }
  }

  // .banner__title
  &__title {
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    color: #373737;
    font-size: clamp(22px, 5vw, 55px);
    line-height: clamp(22px, 5vw, 64.35px);
    @media (max-width: 767px) {
      color: #fff;
      order: -1;
      text-shadow: 2px 2px 2px black;
    }
    @media (max-width: 479px) {
      margin: 0;
    }
  }

  // .banner__button
  &__button {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    line-height: 30.01px;
    color: #ffffff;
    width: 251px;
    height: 60px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 40px;
    background-color: #d07f67;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 22px;
    &:hover,
    &:focus {
      background-color: #a06d2e;
      transition: 0.2s ease 0.2s;
    }
    @media (max-width: 767px) {
      margin-top: 2rem;
      font-size: 18px;
      width: 200px;
      height: 40px;
    }
    @media (max-width: 479px) {
      width: 119px;
      height: 32px;
      font-size: 12px;
      margin-top: 0;
    }
  }
}
</style>
