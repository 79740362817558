<template>
  <!--<div class="under-construction">
    <img src="../assets/images/bg.jpg" alt="" />
  </div>-->
  <plug v-if="isPlug"/>
  <template v-else>
    <app-header :login="login" @authData = "getAuthData"/>
    <router-view/>
    <app-footer />
  </template>
</template>

<script>
import AppFooter from './components/AppFooter.vue';
import AppHeader from './components/AppHeader.vue';
import Plug from './components/Plug.vue';
export default {
  components: { AppHeader, AppFooter, Plug },
  name: "app",
  data() {
    return {
      isPlug: false,
      login: 'Войти'
    }
  },

  methods: {
    getAuthData() {
      if (localStorage.getItem('name') !== 'undefined' && localStorage.getItem('name') !== '') {
        this.login = localStorage.getItem('name')
      } else {
        this.login = 'Личный кабинет'
      }
    }
  },

  mounted() {
    if (localStorage.getItem('token') !== null ) {
      if (localStorage.getItem('name') !== 'undefined' && localStorage.getItem('name') !== '') {
        this.login = localStorage.getItem('name')
      } else {
        this.login = 'Личный кабинет'
      }
    } else {
      this.login = 'Войти'
    }
  },
};
</script>
