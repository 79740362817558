import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "normalize.css";
import { createPinia } from "pinia";
import VueTheMask from "vue-the-mask";
import "./assets/js/slideStories.js";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueTheMask);
app.mount("#app");
