<template>
  <footer class="footer">
    <div class="footer__wrapper center-wrapper">
      <app-logo />
      <div class="footer__body">
        <ul class="footer__menu">
          <li><a href="/#dishes">Каталог меню</a></li>
          <li><router-link to="/reservation">Забронировать</router-link></li>
          <li><a href="/#photos">Фото</a></li>
          <li><a href="/#contacts">Контакты</a></li>
        </ul>
        <div class="footer__copy">
          <span>© Заман, 2023</span>
          <span>ООО "ТЮБЕТЕЙ ЧИСТОПОЛЬСКАЯ"</span>
          <span>Все права защищены</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppLogo from './AppLogo.vue';
export default {
  components: { AppLogo },
  name: "app-footer",
};
</script>

<style lang="scss">
.footer {
  background-color: #2a374b;
  height: 100px;
  display: flex;
  padding: 0 15px;
  @media (max-width: 767px) {
    height: auto;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 58px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    @media (max-width: 767px) {
      justify-content: flex-end;
    }
    @media (max-width: 360px) {
      justify-content: flex-end;
    }
  }
  &__menu {
    display: flex;
    margin-left: 1rem;
    @media (max-width: 1120px) {
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      display: none;
    }
    @media (max-width: 360px) {
      display: none;
    }
    li {
      list-style: none;
      margin-left: 5rem;
      @media (max-width: 1120px) {
        margin-left: 1rem;
        padding: 5px 0;
      }
    }
    a {
      font-family: "Nunito", sans-serif;
      color: #fff;
      line-height: clamp(8px, 5vw, 22px);
      font-size: clamp(8px, 5vw, 18px);
      text-decoration: none;
      &:hover,
      &:focus {
        color: #ccc;
        transition: 0.2s ease 0.2s;
      }
    }
  }
  &__copy {
    display: flex;
    flex-direction: column;
    span {
      font-family: "Nunito", sans-serif;
      color: #fff;
      line-height: clamp(8px, 5vw, 25.2px);
      font-size: clamp(8px, 5vw, 18px);
      @media (max-width: 479px) {
        font-size: 12px;
      }
      @media (max-width: 360px) {
        font-size: 8px;
      }
    }
  }
}
</style>