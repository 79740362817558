<template>
  <div class="certificate-popup">
    <div class="certificate-popup__popup" @click.stop>
      <div class="certificate-popup__close"  @click="closeModal"><img src="../assets/images/svg/exit.svg" :alt="close_img" /></div>
      <div class="certificate-popup__body">
        <h2 v-if="!success" class="certificate-popup__title">{{ title }}</h2>
        <div v-if="!success" class="certificate-popup__input">
          <input type="text" name="tel" v-model="code" :placeholder="placeholder" />
          <button class="certificate-popup__arrow" @click="sendCertif">
            <img src="../assets/images/svg/arrow-right.svg" :alt="arrow_right" />
          </button>
        </div>
        <div v-else>
          <h2 class="certificate-popup__title">Сертификат успешно применен! Вам начислиться {{ amount }} баллов </h2>
        </div>
        <p v-if="!success" class="certificate-popup__subtitle" :class="invalidCode ? 'red' : ''">{{ subtitle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "CertificatePopup",
  data() {
    return {
      title: `Введите номер,\nсертификата`,
      subtitle: `Через 10-15 минут после применения кода\nнаминал сертификата начислится на баланс\nсредств.`,
      close_img: `svg/exit.svg`,
      placeholder: `Номер сертификата`,
      arrow_right: `svg/arrow-right.svg`,
      amount: 0,
      code: '',
      invalidCode: false,
      success: false
    };
  },
  methods: {
    closeModal() {
      this.success = false
      this.$emit('close');
    },
    sendCertif() {
      if (this.code !== '') {
        axios
        .post("https://admin.tcode.online/api/projects/zaman/certificate/", {
          code: this.code
        } ,{
          headers: {
            Authorization: 'Token ' + localStorage.getItem('token')
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status === 'filled') {
            this.amount = response.data.amount
            this.success = true
          }
        })
        .catch((error) => {
          this.invalidCode = true
          this.subtitle = 'Неверный номер сертификата!'
          console.log(error);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.certificate-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  &__popup {
    width: 100%;
    max-width: 591px;
    height: 430px;
    background-color: #fefefe;
    border-radius: 20px;
    position: relative;
    @media (max-width: 767px) {
      max-width: 360px;
      height: 270px;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    width: 18px;
    height: 18px;
    @media (max-width: 767px) {
      top: 0.5rem;
      right: 0.5rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
  }
  &__body {
    width: 100%;
    max-width: 405px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      max-width: 287px;
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: #373737;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #373737;
    margin-top: 1.5rem;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__input {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    overflow: hidden;
    width: 370px;
    height: 39.75px;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      width: 100%;
      height: 31.24px;
    }
    input {
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      color: #7c7f7e;
      background: none;
      padding: 0 1rem;
      border: none;
      outline: none;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  &__arrow {
    width: 40px;
    height: 100%;
    background-color: #efefef;
    padding: 7px;
    border: none;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 31.24px;
    }
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>