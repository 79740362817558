<template>
  <section class="reservation">
    <div class="reservation__body center-wrapper">
      <h2 class="reservation__title">
        Хотите посетить<br />
        ресторан?
      </h2>
      <div class="reservation__subtitle">
        <p>Забронируйте стол онлайн на нашем сайте</p>
      </div>
      <div class="reservation__btn">
        <button class="reservation__button" @click="$router.push('/reservation')" onclick="ym(95816218,'reachGoal','brone'); return true;">Забронировать</button>
      </div>
    </div>
    <div class="reservation__image">
      <img src="../assets/images/reservation.png" alt="reservation" />
    </div>
  </section>
</template>

<script>
export default {
  name: "main-page-reservation",
};
</script>

<style lang="scss" scoped>
.reservation {
  position: relative;
  height: 472px;
  overflow: hidden;
  @media (max-width: 1439px) {
    height: auto;
  }
  @media (max-width: 767px) {
    &::before {
      content: "";
      width: 100%;
      height: 100px;
      position: absolute;
      top: -100px;
      left: 0;
      box-shadow: 0 0 100px 50px;
      z-index: 1;
    }
  }
  &__body {
    position: relative;
    z-index: 1;
    padding: 20px 15px;

    @media screen and (min-width: 1440px) {
      padding: 90px 58px;
    }
  }
  &__title {
    font-family: "Tenor Sans", sans-serif;
    font-weight: 400;
    color: #373737;
    line-height: clamp(18.72px, 5vw, 64.35px);
    font-size: clamp(16px, 5vw, 55px);
    @media (max-width: 767px) {
      color: #fff;
    }
  }
  &__subtitle {
    margin-top: 2rem;
    p {
      font-family: "Nunito", sans-serif;
      color: #373737;
      line-height: clamp(14px, 5vw, 24px);
      font-size: clamp(10px, 5vw, 22px);
      @media (max-width: 767px) {
        color: #fff;
      }
    }
  }
  &__btn {
    margin-top: 2rem;
  }
  &__button {
    width: 231px;
    height: 60px;
    border-radius: 40px;
    background: #D07F67;
    font-family: "Nunito Sans", sans-serif;
    color: #fff;
    border: none;
    cursor: pointer;
    line-height: clamp(13.64px, 5vw, 30.01px);
    font-size: clamp(10px, 5vw, 22px);
    &:hover,
    &:focus {
      background: #d06747;
      transition: 0.2s ease 0.2s;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>