<template>
  <div class="login-popup">
    <div class="login-popup__popup" @click.stop>
      <div class="login-popup__close" @click="closeModal">
        <img src="../assets/images/svg/exit.svg" alt="close" />
      </div>
      <div class="login-popup__body">
        <h2 class="login-popup__title">{{ title }}</h2>
        <p class="login-popup__subtitle">{{ subtitle }}</p>
        <template v-if="verInputCode">
          <div class="login-popup__ver-code">
            <input type="tel" name="code" v-model="verCode" maxlength="4" />
          </div>
          <div class="login-popup__buttons">
            <div class="login-popup__repeat">Повторить SMS</div>
            <div class="login-popup__edit">Изменить данные</div>
          </div>
        </template>
        <template v-else>
          <div class="login-popup__input">
            <input
              type="text"
              name="tel"
              v-mask="'+7 ### ### ## ##'"
              :placeholder="placeholder"
              v-model.trim="phone"
            />
            <button class="login-popup__arrow" @click="setNumberPhone()">
              <img src="../assets/images/svg/arrow-right.svg" alt="arrow" />
            </button>
          </div>
          <div class="login-popup__agree">
            {{ agree }}
            <a href="#">{{ conf }}</a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      title: "Здравствуйте,\nавторизируйтесь",
      subtitle: "Так вы сможете получать баллы и быстрее\nоформлять заказы",
      placeholder: "+7 000 000 00 00",
      agree: "Указывая номер телефона вы принимаете",
      conf: "Политику обработки персональных данных",
      phone: "",
      id_project: 2,
      verCode: "",
      verInputCode: false,
      verCodeError: false,
    };
  },
  methods: {
    setNumberPhone() {
      if (this.phone !== "") {
        axios
          .post("https://admin.tcode.online/api/projects/zaman/auth/phone/", {
            phone: this.phone,
            project: this.id_project,
          })
          .then((response) => {
            console.log(response);
            this.verInputCode = true;
            this.title = `Введите код`;
            this.subtitle = `Мы отправили его на номер\n${this.phone}`;
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    validateCode() {
      const phone = this.phone.replace(/\s+/g, "").slice(1);

      axios
        .post(
          "https://admin.tcode.online/api/projects/zaman/auth/verification/",
          {
            phone: phone,
            code: this.verCode,
          }
        )
        .then((response) => {
          this.$emit("auth", response.data.token);
          this.verCodeError = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.verCodeError = true;
        });
    },

    closeModal() {
      this.verInputCode = false;
      this.$emit("close");
    },
  },
  watch: {
    verCode(newValue) {
      if (newValue.length === 4) {
        this.validateCode();
      }
    },
  },
};
</script>

<style lang="scss">
.login-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  &__popup {
    width: 100%;
    max-width: 591px;
    height: 430px;
    background-color: #fefefe;
    border-radius: 20px;
    position: relative;
    @media (max-width: 767px) {
      max-width: 360px;
      height: 270px;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
  }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    width: 18px;
    height: 18px;
    @media (max-width: 767px) {
      top: 0.5rem;
      right: 0.5rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
  }
  &__body {
    width: 100%;
    max-width: 430px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      max-width: 287px;
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: #373737;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #373737;
    margin-top: 1.5rem;
    white-space: pre;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__input {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    overflow: hidden;
    width: 370px;
    height: 39.75px;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      width: 100%;
      height: 31.24px;
    }
    input {
      width: 100%;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      color: #7c7f7e;
      background: none;
      padding: 0 1rem;
      border: none;
      outline: none;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  &__arrow {
    width: 40px;
    height: 100%;
    background-color: #efefef;
    padding: 7px;
    border: none;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 31.24px;
    }
    &:hover,
    &:focus {
      opacity: 0.5;
      transition: 0.2s ease 0.2s;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__agree {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #7c7f7e;
    margin-top: 1rem;
    text-align: left;
    padding: 0 1rem;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      margin-top: 2rem;
    }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
        transition: 0.2s ease 0.2s;
      }
    }
  }
  &__ver-code {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    input {
      border: none;
      border-bottom: 1px solid #373737;
      font-weight: 600;
      font-size: clamp(16px, 5vw, 22px);
      line-height: clamp(21.82px, 5vw, 30.01px);
      color: #373737;
      text-align: center;
      width: 100%;
      max-width: 230px;
      outline: none;
      @media (max-width: 479px) {
        max-width: 140px;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
  }
  &__repeat,
  &__edit {
    font-size: 20px;
    line-height: 27.28px;
    color: #d07f67;
    cursor: pointer;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 479px) {
      font-size: 12px;
    }
    &:hover,
    &:focus {
      color: #cf6545;
      transition: 0.2s ease 0.2s;
    }
  }
}
</style>
