<template>
  <section class="stories">
    <div class="center-wrapper">
      <div class="stories__scroll">
        <article
          v-for="(item, a) in storiesBase"
          :key="item.id"
          class="stories__item"
          @click="storyPopup(item.id)"
          :value="a"
        >
          <div
            class="stories__img-wrapper"
            :style="{
              height: height - padding + 'px',
              width: width - padding + 'px',
            }"
          >
            <div
              class="stories__img"
              :style="{
                backgroundImage: 'url(' + item.image + ')',
                height: height - padding * 2 + 'px',
                width: width - padding * 2 + 'px',
              }"
            ></div>
          </div>
          <div class="stories__name">{{ item.name }}</div>
        </article>
      </div>
    </div>
  </section>
  <!-- Stories -->
  <div class="mask" :class="isPopup ? `_popup` : ``" @click="storyPopup">
    <div data-slide="slide" class="slide" @click.stop>
      <div
        class="slide-items"
        v-for="(storyItem, index) in storiesBase"
        :key="storyItem.id"
        :value="index"
      >
        <img
          :src="storyItem.image"
          :alt="storyItem.name"
          :class="storyItem.id === popupId ? 'active' : false"
        />
      </div>
      <nav class="slide-nav">
        <div class="slide-thumb">
          <div
            v-for="(line, i) in storiesBase"
            :key="line.id"
            class="slide-line"
            :class="line.id === popupId ? 'active' : false"
            :value="i"
          ></div>
        </div>
        <button class="slide-prev">Prev</button>
        <button class="slide-next">Next</button>
      </nav>
    </div>
  </div>
  <!-- Storie buttons -->
  <!--<button class="btn btn-primary me-2" v-for="(story, index) in stories" :key="index" @click="storyClicked(index)">
    {{ index }}
  </button>-->

  <!-- Stories View -->
  <!-- <div class="overlay" v-show="showSlider">
    <div class="stories_wrapper">
      <stories :autoplay="false" :duration="duration" :stories="stories" width="200px" height="200px" ref="stories_component" @ended="endedEvent" @next_story="nextStoryEvent" @prev_story="prevStoryEvent" @prev_slide="prevSlideEvent" @next_slide="nextSlideEvent" @slide_changed="slideChangedEvent" @swipe_up="swipe_up" @swipe_down="swipe_down" @swipe_left="swipe_left" @swipe_right="swipe_right">
        <template v-slot:slide="{ story, slide }">
          <div class="slide" :style="'background-color:' + slide.color">
            <div>
              <p>{{ story.custom_attribute }}</p>
              <p>Color: {{ slide.color }}</p>
            </div>
          </div>
        </template>
      </stories>
    </div>
  </div>-->
  <!-- /.overlay -->
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
export default {
  name: "main-page-stories",
  created() {},
  data() {
    return {
      isPopup: false,
      storiesBase: [],
      popupId: 0,
      height: "100%",
      width: "100%",
      padding: 15,
    };
  },
  props: {},
  methods: {
    storyPopup(id) {
      this.isPopup = !this.isPopup;
      this.popupId = id;
    },
  },
  mounted() {
    let storiesScrollWidth = document.querySelector(".stories__scroll");
    window.innerWidth < 768 ? (this.padding = 5) : (this.padding = 15);
    axios
      .get(api + `/stories/`)
      .then((response) => {
        //console.log(response.data);
        this.storiesBase = response.data;
        this.height = storiesScrollWidth.clientWidth / this.storiesBase.length;
        this.width = this.height;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.stories {
  background-color: #f7f7f7;
  padding: 15px 0;
  overflow-y: hidden;

  @media screen and (min-width: 1440px) {
    height: 274px;
    padding: 50px 0 30px;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 5px;
  }

  // .stories__scroll
  &__scroll {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: scroll;
    padding: 5px;
    @media screen and (min-width: 1440px) {
      width: 810px;
    }
    @media (max-width: 1439px) {
      width: 711px;
    }
    @media (max-width: 767px) {
      width: 100%;
      justify-content: flex-end;
    }
    // .stories__scroll::-webkit-scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }

  // .stories__item
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 20%;
    @media (max-width: 767px) {
      flex: 1 1 auto;
      width: 25%;
      &:nth-child(2) {
        display: none;
      }
    }
  }
  // .stories__img-wrapper
  &__img-wrapper {
    background: linear-gradient(
      149.89deg,
      #373737 5.14%,
      rgba(55, 55, 55, 0.38) 89.72%
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  // .stories__img
  &__img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 2px solid #f7f7f7;
  }

  // .stories__name
  &__name {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #373737;
    text-align: center;
    margin-top: 1rem;
    @media (max-width: 767px) {
      font-size: 12px;
      margin-top: 5px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 16px;
      line-height: 1.25;
    }
  }
}
</style>
<style lang="css">
.slide {
  max-width: 380px;
  margin: 20px auto;
  display: grid;
  box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.4);
}

.slide-items {
  position: relative;
  grid-area: 1/1;
  border-radius: 5px;
  overflow: hidden;
}

.slide-items img {
  max-width: 100%;
  display: block;
}

.slide-nav {
  grid-area: 1/1;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
}

.slide-nav button {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.slide-items > * {
  position: absolute;
  top: 0px;
  opacity: 0;
  pointer-events: none;
}

.slide-items > .active {
  position: relative;
  opacity: 1;
  pointer-events: initial;
}

.slide-thumb {
  display: flex;
  grid-column: 1 / 3;
}

.slide-thumb > span {
  flex: 1;
  display: block;
  height: 3px;
  background: rgba(0, 0, 0, 0.4);
  margin: 5px;
  border-radius: 3px;
  overflow: hidden;
}

.slide-thumb > span.active::after {
  content: "";
  display: block;
  height: inherit;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  transform: translateX(-100%);
  animation: thumb 5s forwards linear;
}

@keyframes thumb {
  to {
    transform: initial;
  }
}
</style>
